export const qcStatus = ['Escalate', 'Non Qced', 'Qced', 'Automated'];

export const qcStatusMapping: any = {
  'Non Qced': 'to_qc',
  Qced: 'qced',
  Escalate: 'escalate',
  Automated: 'automated'
};

export const Fields = [
  {
    id: 'name_of_the_medicine',
    labelName: 'Name of the Medicine'
  },
  {
    id: 'qualitative_and_quantitative_composition',
    labelName: 'Qualitative and Quantitative Composition'
  },
  {
    id: 'pharmaceutical_form',
    labelName: 'Pharmaceutical Form'
  },
  {
    id: 'therapeutic_indications',
    labelName: 'Therapeutic Indications'
  },
  {
    id: 'dosage_and_method_of_adminstration',
    labelName: 'Dosage and Method of Administration'
  },
  {
    id: 'contraindications',
    labelName: 'Contraindications'
  },
  {
    id: 'special_warning_and_precautions_for_use',
    labelName: 'Special Warnings and Precautions for Use'
  },
  {
    id: 'interactions_other_medicines',
    labelName: 'Interactions with Other Medicines'
  },
  {
    id: 'fertility_pregnancy_lactation',
    labelName: 'Fertility, Pregnancy, and Lactation'
  },
  {
    id: 'effects_ability_drive_use_machines',
    labelName: 'Effects on Ability to Drive and Use Machines'
  },
  {
    id: 'adverse_effects',
    labelName: 'Adverse Effects'
  },
  {
    id: 'overdose',
    labelName: 'Overdose'
  },
  {
    id: 'pharmacodynamic_properties',
    labelName: 'Pharmacodynamic Properties'
  },
  {
    id: 'pharmacokinetic_properties',
    labelName: 'Pharmacokinetic Properties'
  },
  {
    id: 'preclinical_safety_data',
    labelName: 'Preclinical Safety Data'
  },
  {
    id: 'pharmaceutical_particulars',
    labelName: 'Pharmaceutical Particulars'
  },
  {
    id: 'medicine_schedule',
    labelName: 'Medicine Schedule'
  },
  {
    id: 'sponsor',
    labelName: 'Sponsor'
  },
  {
    id: 'date_first_approval',
    labelName: 'Date of First Approval'
  },
  {
    id: 'date_revision',
    labelName: 'Date of Revision'
  }
];

// Options for status dropdown
export const statusOptions = [
  { value: 'all', label: 'All rows' },
  { value: 'to_qc', label: 'Non-Qced' },
  { value: 'qced', label: 'Qced' },
  { value: 'escalate', label: 'Escalate' }
];

// Options for logic_used dropdown
export const logicOptions = [
  { value: 'all', label: 'All Logics' },
  { value: 'contains_quantity', label: 'Contains Quantity Logic' },
  { value: 'uppercase_lowercase', label: 'Uppercase Lowercase Logic' },
  { value: 'remove_parentheses', label: 'Remove Parentheses Logic' },
  { value: 'full_uppercase', label: 'Full Uppercase Logic' },
  { value: 'no_logic', label: 'No Logic' },
  { value: 'curated', label: 'Curated' }
];

export const artgWebsitePrefix = 'https://www.tga.gov.au/resources/artg/';
