import React, { useContext, useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';

import { Box, Container, Grid } from '@material-ui/core';

import PropTypes from 'prop-types';
import Styles from './styles/Home.styles';
import config from '../../config';
import AdminCardTile from '../BaseComponents/AdminCardTile';
import Store from '../../store';
import Actions from '../../store/actions';

const TabPanel = props => {
  const { children, value, index, list, ...other } = props;
  const classes = Styles();

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}>
      {value === index && (
        <Grid container className={classes.grids}>
          {list.map(item => (
            <AdminCardTile
              key={item.link}
              link={item.link}
              title={item.label}
              originalName={item.originalName}
              disabled={'disabled' in item && item.disabled}
            />
          ))}
        </Grid>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  list: PropTypes.array.isRequired
};

export const tabList = [
  {
    id: 0,
    label: 'CDER',
    permission: ['admin', 'curator'],
    list: [
      {
        label: 'Label Curation(New)',
        originalName: 'CDER Label Curation',
        link: 'cder/label'
      },
      {
        label: 'Letter Curation(New)',
        originalName: 'CDER Letter Curation',
        link: 'cder/letter'
      },
      {
        label: 'Letters and Labels Page Numbers',
        originalName: 'Data Curation',
        link: 'reviews'
      },
      {
        label: 'PMR/C',
        originalName: 'PMR Data Curation',
        link: 'pmr/review'
      },
      {
        label: 'Label Text',
        originalName: 'FDA Label Curation',
        link: 'fda/label'
      },
      {
        label: 'Label Section Separation',
        originalName: 'FDA Label Section Separation',
        link: 'fda/label/section'
      },
      {
        label: 'RLD',
        link: 'rld'
      },
      {
        label: 'Snapshot',
        link: 'snapshot'
      },
      {
        label: 'Approval Status',
        link: 'fda'
      },
      {
        label: 'Regulatory Information',
        originalName: 'Sourcing Regulatory Information',
        link: 'regulatoryinformation'
      },
      {
        label: 'ATC Code',
        originalName: 'ATC Code Curation',
        link: 'fda/atc'
      },
      {
        label: 'Pediatrics Indication - Age category',
        originalName: 'Pediatrics Indication - Age category',
        link: 'cder/pediatrics'
      },
      {
        label: 'Invalid File Url Curation',
        originalName: 'Invalid File Url Curation',
        link: 'cder/invalid-files'
      },
      {
        label: 'Formulation Curation',
        originalName: 'Formulation Curation',
        link: 'cder/formulation'
      },
      {
        label: 'Route Curation',
        originalName: 'Route Curation',
        link: 'cder/route'
      }
    ]
  },
  {
    id: 1,
    label: 'EMA',
    permission: ['admin', 'curator'],
    list: [
      {
        label: 'Label Section Separation',
        originalName: 'EMA Label Section Separation',
        link: 'ema/label/section'
      },
      {
        label: 'Source Validation',
        originalName: 'Source Validation',
        link: 'sourceValidation'
      },
      {
        label: 'Label Text',
        originalName: 'EMA Label Curation',
        link: 'ema/labelEma'
      },
      {
        label: 'Procedural Steps',
        originalName: 'EMA Procedural Steps',
        link: 'ema/proceduralStepsEma'
      },
      {
        label: 'Products',
        originalName: 'EMA Products',
        link: 'ema/products'
      },
      {
        label: 'ATC Code',
        originalName: 'EMA ATC Code',
        link: 'ema/atc'
      },
      {
        label: 'Data',
        originalName: 'EMA Data',
        link: 'ema/data'
      }
    ]
  },
  {
    id: 2,
    label: 'Guidance',
    permission: ['admin', 'curator'],
    list: [
      {
        label: 'Guidance PDF Comparison',
        originalName: 'Guidance Curation',
        link: 'guidance'
      },
      {
        label: 'Guidance Update Data',
        originalName: 'Guidance Qcd',
        link: 'guidancedocument'
      }
    ]
  },
  {
    id: 3,
    label: 'EUA',
    permission: ['admin', 'curator'],
    list: [
      {
        label: 'EUA Curation',
        originalName: 'EUA Curation',
        link: 'eua'
      },
      {
        label: 'ATC Code',
        originalName: 'EUA ATC Code',
        link: 'eua/atc/curation'
      }
    ]
  },
  {
    id: 4,
    label: 'AdComm',
    permission: ['admin', 'curator'],
    list: [
      {
        label: 'AdComm',
        originalName: 'AdComm Curation',
        link: 'adcomm'
      },
      {
        label: 'AdComm Application Curation',
        originalName: 'AdComm Curation',
        link: 'adcomm/application'
      }
    ]
  },
  {
    id: 5,
    label: 'CBER',
    permission: ['admin', 'curator'],
    list: [
      {
        label: 'Cber Label Curation ',
        originalName: 'Label Curation',
        link: 'cber'
      },
      {
        label: 'Cber Document Classification',
        originalName: 'Document Classification',
        link: 'document_categorization'
      },
      {
        label: 'Letters and Labels Page Numbers',
        originalName: 'Data Curation',
        link: 'cber/reviews/label'
      },
      {
        label: 'Label Section Separation',
        originalName: 'CBER Label Section Separation',
        link: 'cber/label_section/plr'
      },
      {
        label: 'PMR/C',
        originalName: 'PMR Curation',
        link: 'pmr/cber'
      },
      {
        label: 'ATC Code',
        originalName: 'CBER ATC Code Curation',
        link: 'cber/atc'
      }
    ]
  },
  {
    id: 6,
    label: 'CANADA',
    permission: ['admin', 'curator'],
    list: [
      {
        label: 'Canada Product Monograph Curation ',
        originalName: 'Product Monograph Curation ',
        link: 'canada'
      },
      {
        label: 'Product Monograph Page Number Curation ',
        originalName: 'Product Monograph Page Number Curation ',
        link: 'canada/monograph'
      },
      {
        label: 'Monograph Separation ',
        originalName: 'Monograph Separation ',
        link: 'canada/label_separation'
      }
    ]
  },
  {
    id: 7,
    label: 'PMDA',
    permission: ['admin', 'curator'],
    list: [
      {
        label: 'PMDA Label Curation',
        originalName: 'PMDA Label Curation',
        link: 'pmda'
      },
      {
        label: 'PMDA Section Separation',
        originalName: 'PMDA Section Separation',
        link: '/pmda/section_curation'
      },
      {
        label: 'PMDA Product Resources Page Number Curation',
        originalName: 'PMDA Product Resources Page Number Curation',
        link: '/pmda/page_number_curation'
      },
      {
        label: 'PMDA Auto label Translation',
        originalName: 'PMDA Label Translation',
        link: 'pmda/translate/package-insert'
      },
      {
        label: 'PMDA Complete task',
        originalName: 'PMDA  Complete task',
        link: 'pmda/curation/check'
      }
    ]
  },
  {
    id: 8,
    label: 'HPRA',
    permission: ['admin', 'curator'],
    list: [
      {
        label: 'Section Seperation',
        originalName: 'HPRA Section Seperation',
        link: 'hpra/section'
      },
      {
        label: 'Page Number Curation',
        originalName: 'HPRA Page Number Curation',
        link: 'hpra/page'
      }
    ]
  },
  {
    id: 9,
    label: 'General',
    permission: ['admin', 'curator'],
    list: [
      {
        label: 'Synonyms',
        link: 'synonyms'
      },
      {
        label: 'Db-stats',
        link: 'db_stats'
      },
      {
        label: 'Data Quality',
        link: 'data_quality'
      },
      {
        label: 'Release Version',
        originalName: 'Release Version',
        link: 'release'
      },
      {
        label: 'Notifications Stats',
        originalName: 'Notifications Stats',
        link: 'user_notifications'
      },
      {
        label: 'Whats New',
        originalName: 'Whats New',
        link: 'whats_new'
      },
      {
        label: 'Data Quality Statistics',
        originalName: 'Data Quality Statistics',
        link: 'data_quality_statistics'
      }
    ]
  },
  {
    id: 10,
    label: 'Status',
    permission: ['admin'],
    list: [
      {
        label: 'System status',
        originalName: 'Status',
        link: 'status'
      }
    ]
  },
  {
    id: 11,
    label: 'Dashboard',
    permission: ['admin'],
    list: [
      {
        label: 'Database stats',
        link: ''
      },
      {
        label: 'Other stats',
        link: 'dashboard'
      },
      {
        label: 'Search queries',
        link: 'queries/30/day'
      },
      {
        label: 'ChatRIA stats',
        link: 'chatRIAStats/30/day'
      },
      {
        label: 'User Excel Upload',
        originalName: 'User Excel Upload',
        link: 'user_upload'
      },
      {
        label: 'User Survey',
        originalName: 'User Survey',
        link: 'user_survey'
      }
    ]
  },
  {
    id: 12,
    label: 'Australia',
    permission: ['admin', 'curator'],
    list: [
      {
        label: 'Product Information Page Number Curation ',
        originalName: 'Product Information Page Number Curation ',
        link: 'australia/product_information'
      },
      {
        label: 'Product Info Section Separation',
        originalName: 'Product Info Section Separation ',
        link: 'australia/label_separation'
      },
      {
        label: 'Product Names Curation',
        originalName: 'Product Names Curation',
        link: 'australia/product_name'
      }
    ]
  }
];
const Home = () => {
  const classes = Styles();
  const { state, dispatch } = useContext(Store);
  const [admin, setAdmin] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = await Auth.currentUserInfo();
        if (user.attributes['custom:role'] === 'Admin') {
          setAdmin(true);
        }
        if (
          user.attributes['custom:role'] !== 'Data Curator' ||
          user.attributes['custom:role'] !== 'Admin' ||
          config.allowList.includes(user.attributes.email)
        ) {
          window.location('ria.vivpro.ai');
        }
        await dispatch({ type: Actions.SET_MODULE, value: 'admin' });
      } catch (error) {
        // console.log(error);
      }
    };
    fetchData();
  }, [dispatch]);

  return (
    <Box className={classes.root}>
      <Container maxWidth='lg' className={classes.container}>
        {/* eslint-disable-next-line no-shadow */}
        {tabList.map(({ id, permission, list }) => {
          if (permission.includes('admin') && admin) {
            return <TabPanel value={state.homeTabs} index={id} list={list} />;
          }
          if (permission.includes('curator') && !admin) {
            return <TabPanel value={state.homeTabs} index={id} list={list} />;
          }
          return null;
        })}
      </Container>
    </Box>
  );
};

export default Home;
